.card-box {
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border: none;
}

.page-title {
  font-weight: 600;
  color: #2c3e50;
  display: flex;
  align-items: center;
}

.page-title i {
  font-size: 1.5rem;
  color: #3498db;
}

.action-btn {
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.action-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.action-btn:active {
  transform: translateY(0);
}

.action-btn i {
  font-size: 1.1rem;
}

.filter-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.filter-label {
  font-weight: 500;
  color: #495057;
}

.date-input {
  border-radius: 0 6px 6px 0;
  padding: 10px 12px;
}

.date-icon {
  background-color: #e9ecef;
  border-radius: 6px 0 0 6px;
}

.filter-buttons {
  justify-content: flex-end;
}

.filter-btn {
  padding: 9px 16px;
  font-weight: 500;
}

.reset-btn {
  padding: 9px 16px;
  font-weight: 500;
  border-color: #ced4da;
}

/* Table Styles */
.payment-table {
  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

.payment-table thead tr {
  background-color: #f8f9fa;
}

.payment-table th {
  font-weight: 600;
  padding: 16px;
  border-bottom: 2px solid #dee2e6;
  color: #495057;
  vertical-align: middle;
}

.payment-table td {
  padding: 16px;
  vertical-align: middle;
  border-bottom: 1px solid #e9ecef;
}

.payment-table tbody tr:last-child td {
  border-bottom: none;
}

.payment-table tbody tr:hover {
  background-color: #f1f4f9;
}

.checkbox-col {
  width: 48px;
  text-align: center;
}

.id-col {
  width: 60px;
}

.paid-row {
  background-color: #f8fffa;
}

.id-value {
  font-weight: 600;
  color: #495057;
}

.amount-value {
  font-weight: 500;
  color: #2c3e50;
}

.check-id {
  font-family: monospace;
  color: #495057;
}

.date-value {
  color: #6c757d;
}

.status-col {
  width: 140px;
  text-align: center;
}

.status-badge {
  padding: 8px 12px;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 80px;
  display: inline-block;
}

.status-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.paid-badge {
  background-color: #28a745 !important;
}

.pending-badge {
  background-color: #ffc107 !important;
  color: #212529 !important;
}

.record-checkbox, .select-all-checkbox {
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-input {
    width: 15px;
    height: 15px;
    margin: 0 auto;
}

/* Export Modal Styles */
.export-modal .modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.export-modal .modal-title {
  font-weight: 600;
  color: #2c3e50;
  display: flex;
  align-items: center;
}

.export-label {
  font-weight: 500;
  color: #495057;
  margin-bottom: 12px;
}

.format-selector {
  display: flex;
  gap: 16px;
}

.format-option {
  flex: 1;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.format-option:hover {
  border-color: #ced4da;
  background-color: #f8f9fa;
}

.format-option.selected {
  border-color: #3498db;
  background-color: #ebf5fb;
}

.format-icon {
  font-size: 2rem;
  margin-right: 12px;
  color: #3498db;
}

.format-details {
  display: flex;
  flex-direction: column;
}

.format-name {
  font-weight: 600;
  color: #2c3e50;
}

.format-ext {
  font-size: 0.85rem;
  color: #6c757d;
}

.export-summary {
  margin-top: 24px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
}

.summary-icon {
  font-size: 1.5rem;
  color: #6c757d;
  margin-right: 12px;
  margin-top: 2px;
}

.summary-text p {
  margin-bottom: 4px;
}

.date-range-info {
  color: #6c757d;
}

.cancel-btn, .export-btn {
  padding: 8px 16px;
  font-weight: 500;
}

.export-btn {
  display: flex;
  align-items: center;
}

/* Empty state */
.text-center {
  padding: 40px 0;
  color: #6c757d;
}

.select-all-checkbox input{
    margin-top: 1rem;
}